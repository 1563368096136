.background-derivator-pages {
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
  height: 100vh;
}

.background-bottom-black-purple {
  position: absolute;
  background-color: var(--black-purple);
  width: 89vw;
  height: 55vh;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: -5;
  border-radius: 24px 24px 0 0;
}

@media screen and (max-width: 1000px) {
  .background-bottom-black-purple {
    width: calc(100vw + 0.9px);
  }
}

.background-derivator-purple {
  height: 100%;
}

.icons-mobile {
  height: 70px;
}

.icons-mobile-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.icon-mobile-description {
  color: #fff;
  text-align: center;
  font-size: 15px;
  font-style: normal;
  font-weight: bold;
  line-height: normal;
  margin-top: 10px;
}

.subtitle-mobile {
  color: #864bfa;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.container-all-cotizador {
  height: 92vh;
}

.container-cotizador {
  background-position: bottom center;
  background-size: contain;
  background-repeat: no-repeat;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
}

.logo-auta {
  width: 124px;
  height: 60px;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.modal-success {
  padding: 20px;
  border-radius: 16px;
  overflow: auto;
  position: relative;
  background-position: top center;
  background-repeat: no-repeat;
  background-size: contain;
  overflow: hidden;
}

.text-error {
  color: #f40000;
  font-size: 12px;
  font-weight: 500;
}

.text-wait {
  color: var(--purple);
  font-size: 26px;
  font-weight: 600;
  text-align: center;
}

.card-contact-data-size {
  width: 343px;
  height: 153px;
}
.text-down-input {
  color: var(--dark-purple);
  font-family: Mark Pro;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;
  margin-top: 4px;
}

.cotizador-mobile {
  display: none;
}

@media screen and (max-width: 1000px) {
  .cotizador-mobile {
    display: flex;
    flex-direction: column;
  }
  .cotizador-desktop {
    display: none;
  }
}

@media screen and (max-width: 450px) {
  .container-cotizador {
    background-position: bottom center;
    background-size: 100% 60vh;
    background-repeat: no-repeat;
    height: 100%;
  }
  .text-wait {
    font-size: 24px;
  }
  .modal-success {
    padding: 0px;
    border-radius: 16px;
    border: none;
  }
  .card-contact-data-size {
    width: auto;
    height: 200px;
    padding-left: 10px;
    padding-right: 10px;
  }
  .card-icon-cotizacion {
    margin-top: 30px;
  }
}
